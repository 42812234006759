var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('indexHeader',{attrs:{"title":_vm.$t('manufacturingDeposits.ManufacturingDeposits'),"description":_vm.$t('manufacturingDeposits.FromHereYouCanControlManufacturingProcessDeposits')}}),_c('indexTable',{attrs:{"searchText":_vm.$t('manufacturingDeposits.SearchForAnOperation'),"emptyTableText":_vm.$t('manufacturingDeposits.ThereAreNoWithdrawalsWithManufacturingProcesses'),"emptyTableSubText":_vm.$t('allerts.TryAddingSomeItemsToTheTableAndTryAgain'),"statusSearch":true,"statusValues":[
          { title: _vm.$t('orders.situation'), value: ''},
          { title: _vm.$t('orders.all'), value: ''},
          { title: _vm.$t('invoices.draft'), value: 0 },
          { title: _vm.$t('invoices.approved'), value: 1 } ],"buttonmanufacturing":false,"cloumns":[
          {
            column: 'code',
            title: _vm.$t('manufacturingDeposits.operation'),
            type: 'mainLink',
            sort: true,
          },
          {column: 'manufacturing_operation_id',    title: _vm.$t('manufacturingDeposits.TheCodeOfTheOperation') ,type: 'linkInvoice', to:'manufacturingOperation', sort: true, link: true},
          {
            column: 'store_id',
            title: _vm.$t('manufacturingDeposits.TheNameOfTheStore'),
            type: 'link',
            to: 'store',
            sort: true,
            link: true,
          },
          {
            column: 'date',
            title: _vm.$t('manufacturingDeposits.theDateOfTheOperation'),
            type: 'text',
            sort: true,
          },
          {
            column: 'cost',
            title: _vm.$t('manufacturingDeposits.cost'),
            type: 'text',
            sort: true
          },
          {
            column: 'description',
            title: _vm.$t('manufacturingDeposits.thatAbout'),
            type: 'text',
            sort: true,
          },
          {column: 'status', title: _vm.$t('bills.situation') ,type: 'status' , sort: true ,  values: [
              {title: _vm.$t('invoices.draft'), value: 0, color: 'dark'},
              {title: _vm.$t('invoices.approved'), value: 1, color: 'success'} ]
          },
          {
            column: 'options',
            title: _vm.$t('manufacturingDeposits.settings'),
            type: 'options',
            options: [
              { name: 'show' },
              {
                name: 'edit',
                role:
                  _vm.$user.admin ||
                  _vm.$user.role.manufacturing_operations_edit,
              },
              {name: 'file'},
              { name: 'printmanufacturingDeposit', role: true },
              {
                name: 'delete',
                role:
                  _vm.$user.admin ||
                  _vm.$user.role.manufacturing_operations_edit,
              } ],
          } ],"deleteText":{
          attention: _vm.$t('allerts.Attention'),
          areYouReallySureToDeleteTheItem:
            _vm.$t('allerts.areYouReallySureToDeleteTheItem'),
          close: _vm.$t('allerts.close'),
          confirm: _vm.$t('allerts.confirm'),
          loading: _vm.$t('allerts.loading'),
        }}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }